








import CopyStakeStreamerSettingsForm from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamerSettingsForm.vue';
import CopyStakeStreamerDisclaimer from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamerDisclaimer.vue';
import CopyStakeStreamersTable from '@/views/CopyStake/CopyStakeStreamer/CopyStakeStreamersTable.vue';
import FeatureFlagRedirectMixin from '@/mixins/copy-stake/FeatureFlagRedirectMixin';

export default {
  name: 'CopyStakeStreamersView',
  mixins: [FeatureFlagRedirectMixin],
  components: {
    CopyStakeStreamersTable,
    CopyStakeStreamerSettingsForm,
    CopyStakeStreamerDisclaimer
  }
};
