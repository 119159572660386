import { http } from '@/api/Connect';
import { BASE_WEB_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

const API_ADMIN_STREAMER = (): string => `${BASE_WEB_OPERATOR()}/streamer`;

export async function startStreamerBroadcast(
  streamerId: string
): Promise<void> {
  return http
    .patch(`${API_ADMIN_STREAMER()}/${streamerId}/broadcast/start`)
    .then(({ data }: AxiosResponse) => data);
}

export async function stopStreamerBroadcast(streamerId: string): Promise<void> {
  return http
    .patch(`${API_ADMIN_STREAMER()}/${streamerId}/broadcast/stop`)
    .then(({ data }: AxiosResponse) => data);
}
