













export default {
  name: 'CopyStakeStreamerDisclaimer'
};
