








































































































import { mapGetters } from 'vuex';

const ConfirmationDialog = (): Promise<any> =>
  import('@/components/ConfirmationDialog.vue');

import {
  getStreamConfiguration,
  updateStreamConfiguration
} from '@/api/CopyStake/Configuration';
import { TStreamConfigurationResponse, ValidationRule } from '@/api/schema';

import { EPermissionSuperAdmin } from '@/plugins/role-permissions/types/permission-subject';
import {
  positiveNumber,
  lte,
  validNumber,
  integer
} from '@/helpers/validation';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'CopyStakeStreamerSettingsForm',
  components: {
    ConfirmationDialog
  },
  data(): any {
    return {
      limit: {
        maxBetVolume: '',
        maxBetVolumeActive: true
      },
      defaultBet: '',
      inactivityTimeout: '',
      confirmData: {
        cancelText: 'Cancel',
        okText: `Yes, I'm sure`,
        header: 'Show only streamers',
        disclaimer: 'Are you sure you want to enable this feature?',
        okButtonType: 'primary'
      },
      showOnlyStreamers: false,
      isLoading: false,
      formValid: false,
      limitValue: '',
      defaultBetValue: '',
      inactivityTimeoutValue: '',
      credentials: {
        secretKey: '',
        apiKey: '',
        streamUrl: ''
      },
      credentialsShow: {
        secretKey: true,
        apiKey: true
      },
      credentialsValue: {
        secretKey: '',
        apiKey: '',
        streamUrl: ''
      },
      showConfirm: false
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),

    showLiveKitSettings(): boolean {
      return this.$role.can.update(
        EPermissionSuperAdmin.SUPER_ADMIN_LIVEKIT_SETTINGS
      );
    },
    canEdit(): boolean {
      return this.$role.can.update('copy_stake streamers');
    },
    limitChanged(): boolean {
      return `${this.limitValue}` !== `${this.limit.maxBetVolume}`;
    },
    defaultBetChanged(): boolean {
      return `${this.defaultBetValue}` !== `${this.defaultBet}`;
    },
    inactivityTimeoutChanged(): boolean {
      return `${this.inactivityTimeoutValue}` !== `${this.inactivityTimeout}`;
    },
    valueCanBeSaved(): boolean {
      return (
        this.formValid &&
        (this.limitChanged ||
          this.defaultBetChanged ||
          this.inactivityTimeoutChanged ||
          this.credentialsChanged)
      );
    },
    credentialsIsSet(): boolean {
      return Object.values(this.credentials).every(
        (key: string) => typeof key === 'string' && key?.length
      );
    },
    credentialsChanged(): boolean {
      return (
        JSON.stringify(this.credentialsValue) !==
        JSON.stringify(this.credentials)
      );
    },
    limitRules(): ValidationRule[] {
      return [
        (v: any) => v === '' || +v === 0 || positiveNumber(v),
        (v: any) => v === '' || validNumber(v),
        (v: any) => v === '' || lte(v, 100)
      ];
    },
    defaultBetRules(): ValidationRule[] {
      return [
        (v: any) => positiveNumber(v),
        (v: any) => validNumber(v),
        (v: any) =>
          !this.limitValue ||
          lte(v, this.limitValue, "Default Bet Amount can't exceed Limit")
      ];
    },
    inactivityTimeoutRules(): ValidationRule[] {
      return [
        (v: any) => v === '' || positiveNumber(v),
        (v: any) => v === '' || validNumber(v),
        (v: any) => v === '' || integer(v)
      ];
    }
  },
  watch: {
    operatorId: {
      handler: 'getConfiguration',
      immediate: true
    }
  },
  methods: {
    validateForm(): void {
      this.$refs.form.validate();
    },
    async getConfiguration(): Promise<void> {
      if (!this.operatorId) {
        return;
      }

      this.isLoading = true;

      getStreamConfiguration()
        .then((data: TStreamConfigurationResponse) => {
          const limit = data.maxBetVolume ? `${data.maxBetVolume}` : '';

          this.limit.maxBetVolume = limit;
          this.limitValue = limit;
          this.limit.maxBetVolumeActive = data.maxBetVolumeActive;

          this.defaultBetValue = data.defaultBetVolume;
          this.defaultBet = data.defaultBetVolume;

          this.inactivityTimeoutValue = data.streamerInactiveTimeout || '';
          this.inactivityTimeout = data.streamerInactiveTimeout || '';

          this.showOnlyStreamers = data.showOnlyStreamers;
          this.credentials.apiKey = data.credentialsKey;
          this.credentials.secretKey = data.credentialsSecret;
          this.credentials.streamUrl = data.streamUrl;
          this.credentialsValue.apiKey = data?.credentialsKey || '';
          this.credentialsValue.secretKey = data?.credentialsSecret || '';
          this.credentialsValue.streamUrl = data?.streamUrl || '';
        })
        .finally(() => {
          this.credentialsShow.apiKey = false;
          this.credentialsShow.secretKey = false;
          this.isLoading = false;
        });
    },
    async updateConfiguration(changeStreamerShow = false): Promise<void> {
      const payload: Partial<TStreamConfigurationResponse> = {};
      this.isLoading = true;

      if (this.limitChanged) {
        if (this.limitValue === '' || +this.limitValue === 0) {
          payload.maxBetVolumeActive = false;
        } else {
          payload.maxBetVolume = this.limitValue;
        }
      }

      if (this.defaultBetChanged) {
        payload.defaultBetVolume = this.defaultBetValue;
      }

      if (this.inactivityTimeoutChanged) {
        payload.streamerInactiveTimeout = this.inactivityTimeoutValue;
      }

      if (changeStreamerShow) {
        payload.showOnlyStreamers = !this.showOnlyStreamers;
      }

      if (this.credentialsChanged) {
        payload.credentialsKey = this.credentialsValue.apiKey;
        payload.credentialsSecret = this.credentialsValue.secretKey;
        payload.streamUrl = this.credentialsValue.streamUrl;
      }

      await updateStreamConfiguration(payload)
        .then(() => {
          if (+this.limitValue === 0) {
            this.limitValue = '';
          }

          this.limit.maxBetVolume = this.limitValue;
          this.limit.maxBetVolumeActive = !!payload.maxBetVolumeActive;
          this.defaultBet = this.defaultBetValue;
          this.inactivityTimeout = this.inactivityTimeoutValue;

          if (changeStreamerShow) {
            this.showOnlyStreamers = payload.showOnlyStreamers;
          }

          if (this.credentialsChanged) {
            this.credentials.apiKey = payload.credentialsKey;
            this.credentials.secretKey = payload.credentialsSecret;
            this.credentials.streamUrl = payload.streamUrl;
          }

          this.$toast.success('Settings was successfully updated!');
        })
        .catch(errorToastMessage)
        .finally(() => {
          this.isLoading = false;
        });
    },

    getHiddenAttrs(key: string): any {
      const label = key === 'apiKey' ? 'API Key' : 'Secret Key';
      const appendIcon = this.credentialsShow[key]
        ? 'mdi-eye'
        : 'mdi-eye-off';
      const type = this.credentialsShow[key] ? 'text' : 'password';

      return {
        label: `LiveKit ${label}`,
        outlined: true,
        dense: true,
        appendIcon: this.canEdit ? '' : appendIcon,
        type: this.canEdit ? 'text' : type,
        autocomplete: this.canEdit ? 'off' : 'new-password'
      };
    },
    toggleVisibleStatus(key: string): void {
      this.credentialsShow[key] = !this.credentialsShow[key];
    },
    handleSettingsUpdate(): void {
      this.updateConfiguration(true).finally(() => {
        this.showConfirm = false;
      });
    },
    handleStreamerChange(): void {
      if (this.isLoading || !this.canEdit) return;

      if (!this.showOnlyStreamers) {
        this.showConfirm = true;
      } else {
        this.handleSettingsUpdate(true);
      }
    }
  }
};
